<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }} {{ data.name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Account Code</div>
        <div class="breadcrumb-item">{{ name }} {{ data.name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="row" v-if="!loading">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} {{ data.name }}</h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Name</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="data.name"
                />
              </div>
              <div class="form-group">
                <label>Code</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="data.code"
                />
              </div>
              <div class="form-group">
                <label>Total Amount</label>
                <h3>Rp. {{ formatPrice(data.total_amount) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>Cashflow {{ data.name }}</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="cashFlowInsert">
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Status</label>
                      <select
                        v-model="form_create.status"
                        class="form-control"
                        id=""
                      >
                        <option value="">Select Status</option>
                        <option value="IN">IN</option>
                        <option value="OUT">OUT</option>
                      </select>
                      <small class="text-danger" v-if="errors.status">{{
                        errors.status[0]
                      }}</small>
                    </div>
                    <div class="form-group col">
                      <label>Date</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="form_create.reported_at"
                      />
                      <small class="text-danger" v-if="errors.reported_at">{{
                        errors.reported_at[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Payment Type</label>
                    <model-list-select
                      :list="payment_type"
                      v-model="form_create.payment_type_id"
                      option-value="id"
                      option-text="name"
                      placeholder="Select Payment Type"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.payment_type_id">{{
                      errors.payment_type_id[0]
                    }}</small>
                  </div>
                  <div class="form-group">
                    <label>Amount</label>
                    <money
                      v-model="form_create.amount"
                      class="form-control"
                    ></money>
                    <small class="text-danger" v-if="errors.amount">{{
                      errors.amount[0]
                    }}</small>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control"
                      v-model="form_create.description"
                      style="height:100px"
                    ></textarea>
                    <small class="text-danger" v-if="errors.description">{{
                      errors.description[0]
                    }}</small>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <button
                    class="btn btn-primary mr-1"
                    type="submit"
                    :disabled="isSubmitting"
                  >
                    Submit
                  </button>
                  <button class="btn btn-secondary" type="reset">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <cashflow-parent
            :datas="data.cashflow_parent"
            :parentName="data.name"
          ></cashflow-parent>
        </div>
        <div class="col-md-12">
          <cashflow-children
            :datas="data.cashflow_child"
            parentName="Child"
          ></cashflow-children>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { apiGet } from "../../services/api";
import CashflowParent from "./cashFlow/CashflowParent.vue";
import CashflowChildren from "./cashFlow/CashflowChildren.vue";
import axios from "axios";

export default {
  name: "EditProduct",
  components: {
    CashflowParent,
    CashflowChildren,
  },
  data() {
    return {
      name: "Detail Account Code",
      data: [],
      form_create: {
        account_code_id: this.$route.params.id,
        payment_type_id: "",
        status: "",
        amount: "",
        description: "",
        reported_at: "",
      },
      isSubmitting: false,
      errors: {},
      payment_type: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
    this.getPaymentType();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("account_code/" + id)
        .then((result) => {
          this.data = result.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPaymentType() {
      apiGet("payment_type")
        .then((result) => {
          this.payment_type = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cashFlowInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "cashflow/store", this.form_create, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your Cash Flow has been created!");
          this.isSubmitting = false;
          this.form_create.account_code_id = this.$route.params.id;
          this.form_create.payment_type_id = "";
          this.form_create.status = "";
          this.form_create.amount = "";
          this.form_create.description = "";
          this.form_create.reported_at = "";
          this.getData();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
