<template>
  <div class="card">
    <div class="card-header">
      <h4>{{ name }} {{ parentName }} Table</h4>
      <div class="card-header-form">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            v-model="search"
            label="Search"
          />
          <div class="input-group-btn">
            <button class="btn btn-primary">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <v-data-table
        :headers="headers"
        :items="datas"
        item-key="id"
        :search="search"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.reported_at }}</td>
            <td>{{ row.item.created_by_name }}</td>
            <td>{{ row.item.account_code_child }}</td>
            <td>{{ row.item.payment_type_name }}</td>
            <td>
              <div
                class="badge"
                :class="{
                  'badge-success': row.item.status == 'IN',
                  'badge-danger': row.item.status == 'OUT',
                }"
              >
                {{ row.item.status }}
              </div>
            </td>
            <td>Rp. {{ formatPrice(row.item.amount) }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div class="card-footer text-right">
      <nav class="d-inline-block">
        <ul class="pagination mb-0">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  props: ["datas", "parentName"],
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      name: "Cashflow Data",
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Reported at",
          value: "reported_at",
        },
        {
          text: "Created By",
          value: "created_by_name",
        },
        {
          text: "Account Code Group",
          value: "account_code_child",
        },
        {
          text: "Payment Type",
          value: "payment_type",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Amount",
          value: "amount",
        },
        // { text: "Action" },
      ];
    },
  },
};
</script>
